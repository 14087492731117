import React from 'react'
import WorkItem from './WorkItem'
import img1 from '../Assets/img/brain.jpg'
import img2 from '../Assets/img/counselling.jpg';
import img3 from '../Assets/img/mental.jpg'
import img4 from '../Assets/img/speech.jpg'
import img5 from '../Assets/img/day.jpg'
import img6 from '../Assets/img/nutrition.jpg'
import img7 from '../Assets/img/psych.jpg'
import img8 from '../Assets/img/education.jpg'
import img9 from '../Assets/img/reading.jpg'
import img10 from '../Assets/img/rp.jpg'


const Works = () => {
    const pillstyle={
        border: "none",
        background: "transparent",
        color: "white"
    }
    return (
        <>
            <section class="container py-5">
                <div class="row justify-content-center my-5 bg-secondary">
                    <div class="filter-btns shadow-md rounded-pill text-center col-auto">
                        <button style={pillstyle} class="filter-btn btn rounded-pill btn-outline-primary border-0 m-md-2 px-md-4 active" data-filter=".project">All</button>
                        <button style={pillstyle} class="filter-btn btn rounded-pill btn-outline-primary border-0 m-md-2 px-md-4" data-filter=".children">Children</button>
                        <button style={pillstyle} class="filter-btn btn rounded-pill btn-outline-primary border-0 m-md-2 px-md-4" data-filter=".adult">Adult</button>
                        <button style={pillstyle} class="filter-btn btn rounded-pill btn-outline-primary border-0 m-md-2 px-md-4" data-filter=".daytherapy">Day Therapy</button>
                    </div>
                </div>

                <div class="row projects gx-lg-5">
                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project children"
                        img={img1}
                        heading="Brainobrain Program"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project children adult"
                        img={img2}
                        heading="Counselling"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project children adult"
                        img={img3}
                        heading="Mental Health Training"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project children"
                        img={img4}
                        heading="Speech and Language"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project daytherapy"
                        img={img5}
                        heading="Day Therapy"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project adult"
                        img={img6}
                        heading="Nutrition and Diet"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project adult children"
                        img={img7}
                        heading="Pyschological Service"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project adult"
                        img={img8}
                        heading="School Based Support"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project daytherapy children"
                        img={img9}
                        heading="School Readiness Program"
                        description=""
                    />

                    <WorkItem
                        category="col-sm-6 col-lg-4 text-decoration-none project children"
                        img={img10}
                        heading="Reading Program"
                        description=""
                    />

                    
                </div> 
            </section>
        </>
    )
}

export default Works

import React from 'react'

const WorkItem = (props) => {
    const styles={
        boxShadow:"0px 4px 8px 0 grey",
        borderRadius: "10px"
    }
    return (
        <>
            <div href="work-single.html" class={props.category}>
                <div style={styles} class="service-work overflow-hidden card mx-5 mx-sm-0 mb-5">
                    <img class="card-img-top" src={props.img} alt="..." />
                    <div class="card-body">
                        <h5 class="card-title light-300 text-dark">{props.heading}</h5>
                        <p class="card-text light-300 text-dark">
                            {props.description}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WorkItem

import React from 'react';
import HeroSection from '../Components/HeroSection';
import AboutSection from '../Components/AboutSection';
import aboutimg from '../Assets/img/about.jpg'
import Services from '../Components/Services';
import Testimonials from '../Components/Testimonials';
import Mission from '../Components/Mission';

const Home = () => {
    return (
        <>
            <HeroSection />
            <AboutSection 
                img={aboutimg}
                heading="About Us"
                info={<fragment>We believe that cognitive skills training is about exercising the brain to think faster and learn easily. 
                        Different children have different needs that is why our programs target the areas that need strengthening whether
                        it be memory, auditory processing, visual processing, attention, working memory, processing speed or logic and 
                        reasoning.<br/><br/>
                        <ul>
                        <li>Take the first step today and register for an assessment</li>
                        <li>Second step is going through our programs</li>
                        <li>Third step is enjoy your new found abilities and continue on your path of exploring more possibilities</li>
                        </ul>
                        Our training is Diagnostic, individual and developmental.  Our trainers are qualified and experienced to deliver timely, systematic and effective interventions.
                        All our programs are research based and international.</fragment>}
            />
            <Services />
            <Mission color="banner-bg bg-light py-5"/><br></br>
            <Testimonials/>
        </>
    )
}

export default Home;

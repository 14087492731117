import React from 'react';

const ServiceItem = (props) => {
  return (
    <>
            <div class={props.classinfo}>
                <div  class="service-work card border-0 text-white shadow-sm overflow-hidden mx-5 m-sm-0">
                    <img class="service card-img" src={props.src} alt="Card" />
                    <div class="service-work-vertical card-img-overlay d-flex align-items-end">
                        <div class="service-work-content text-left text-light">
                            <span class="btn btn-outline-light rounded-pill mb-lg-3 px-lg-4 light-300">{props.head}</span>
                            <p class="card-text">{props.info}</p>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default ServiceItem;

import React from 'react';
//import of page components
import NiceHero from '../Components/NiceHero'
import FormSection from '../Components/FormSection';

const Contact = () => {
  return (
    <>
    <NiceHero 
        title="Contact Us"
        subtitle="Fastest way to contact us."
        info="Get in touch with us either through this platform or by using the contact details stated below or visiting our office."
    />
    <FormSection />
    </>
  )
}

export default Contact;

import React from 'react';
import MissionItem from './MissionItem';

const Mission = (props) => {
  return (
    <>
        <section className={props.color}>
            <div className="container my-4">
                <div className="row text-center">
                    <MissionItem
                        icon="display-4 bx bxs-bulb text-light"
                        title="Our Vision"
                        desc="To develop individuals who strive to achieve their highest potential in schools, communities and at home."
                    />

                    <MissionItem
                        icon="display-4 bx bxs-select-multiple text-light"
                        title="Our Values"
                        desc={<fragment>
                                <ul>
                                <li>Respect</li>
                                <li>Integrity</li>
                                <li>Timeliness</li>
                                <li>Systematic Interventions</li>
                                </ul>
                            </fragment>}
                    />

                    <MissionItem
                        icon="display-4 bx bx-revision text-light"
                        title="Our Mission"
                        desc="PCS Development Center seeks to help individuals realise their unique possibilities    undiscovered."
                    />
                </div>
            </div>
        </section>
    </>
  )
}

export default Mission

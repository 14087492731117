import React from 'react';
import './Assets/templatemo.css';
import './Assets/custom.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import of components needed in the website
import Navbar from './Components/Navbar';
import Foot from './Components/Foot';

// import  of site pages
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import About from './Pages/About';
import NotFound from './Pages/NotFound'
import OurWork from './Pages/OurWork';


const App = () => {
  return (
    <Router>
      <div>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/our-work' element={<OurWork />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
          <Foot />
      </div>
    </Router>
    
  )
}

export default App;
import React from 'react';
import HeroItem from './HeroItem';

const HeroSection = () => {
    return (
        <div className="banner-wrapper bg-light">
            <div id="index_banner" className="banner-vertical-center-index container-fluid pt-5">

                {/* start of the slider*/}
                <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active"></li>
                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"></li>
                        <li data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">
                        <HeroItem 
                            active = "carousel-item active"
                            heading = "Creating a Free Mindset."
                            info = "Life is about the richness of the infinite possibilities. Just by you being unique contributes to the TRUE beauty of the world we live in."
                        />
                        
                        <HeroItem 
                            active = "carousel-item"
                            heading = "Reflecting the Possibilities."
                            info = "Helping people reflect and review their maximum potential with maximum support and training"
                        />

                        <HeroItem 
                            active = "carousel-item"
                            heading = "Unveiling the hidden talents"
                            info = "PCS Development Center seeks to help individuals realise their unique possibilities undiscovered."
                        />
                        
                    </div>
                    <a className="carousel-control-prev text-decoration-none" href="#carouselExampleIndicators" role="button" data-bs-slide="prev">
                        <i className='bx bx-chevron-left'></i>
                        <span className="visually-hidden">Previous</span>
                    </a>
                    <a className="carousel-control-next text-decoration-none" href="#carouselExampleIndicators" role="button" data-bs-slide="next">
                        <i className='bx bx-chevron-right'></i>
                        <span className="visually-hidden">Next</span>
                    </a>
                </div>
                {/* start of the slider of the page*/}

            </div>
        </div>
    )
}

export default HeroSection

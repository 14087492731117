import React from 'react'
import '../Assets/team.css'

import img1 from '../Assets/img/esther.jpg';
import img2 from '../Assets/img/rosemary.jpg';
import img3 from '../Assets/img/ashley.jpg'

const Team = () => {
    
const ReadMore = (teami) =>{
            let dots = document.querySelector(`.our-team[data-city="${teami}"] .dots`);
            let moreText = document.querySelector(`.our-team[data-city="${teami}"] .more`);
            let btnText = document.querySelector(`.our-team[data-city="${teami}"] .myBtn`);

            if (dots.style.display === "none") {
                dots.style.display = "inline";
                btnText.innerHTML = "Read more";
                moreText.style.display = "none";
            } else {
                dots.style.display = "none";
                btnText.textContent = "Read less";
                moreText.style.display = "inline";
            }
}
  return (
    <section class="container bg-light py-5">
        <div class="pt-5 pb-3 d-lg-flex align-items-center gx-5">

                <div className="row">
                    <h2 className="h2 text-center col-12 py-5 semi-bold-600">Meet The Team</h2>
                    <div className="service-header col-2 col-lg-3 text-end light-300">
                        <i className='bx bx-gift h3 mt-1'></i>
                    </div>
                    <div className="service-heading col-10 col-lg-9 text-start float-end light-300">
                        <h2 className="h3 pb-4 typo-space-line">A Team comprised of dedicated Personnels</h2>
                    </div>
                </div>
            <div class="col-lg-9 row">
                <div class="col-md-4 col-sm-6">
                    <div class="our-team" data-city="esther">
                        <div class="pic">
                            <img src={img1} alt='member pic'/>
                        </div>
                        <h3 class="title">Esther Moyo</h3>
                        <span class="post">Graduate Member British Psychological Society</span>
                        <ul class="social">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100069952112245"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/possibilitiescognitivecentre/"><i class="fa fa-instagram"></i></a>
                            </li>
                        </ul>

                        <p class="read">Esther is a mature individual who is well trained to provide advice, support and resources to Children, <span class='dots' id="dots"> ........</span>
                        <span class='more'id="more">
                        <br/><br/>
                            <h2 class="title">Qualifications:</h2><br/>
                                <ul>
                                    <li>Diploma in Training/Personnel Management from IPMZ</li>
                                    <li>BA Health Sciences and Social Services (Psychological Counselling)</li>
                                    <li>BA Honours Psychology</li>
                                    <li>MSc. Counselling Psychology (current student)</li>
                                    <li>British Psychological Society Graduate Membership number 566458</li>
                                </ul>
                        </span></p>
                        <button value="esther" class="myBtn btn btn-secondary" onClick={e => ReadMore(e.target.value)} id="myBtn">Read More</button>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="our-team" data-city="rosemary">
                        <div class="pic">
                            <img src={img2} alt='member pic'/>
                        </div>
                        <h3 class="title">Rosemary Magwenzi</h3>
                        <span class="post">Qualified Social Worker</span>
                        <ul class="social">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100069952112245"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/possibilitiescognitivecentre/"><i class="fa fa-instagram"></i></a>
                            </li>
                        </ul>

                        <p class="read">Rosemary is a mature, registered Social Worker who is well trained to provide advice, support and resources to Children,<span class='dots' id="dots"> ........</span>
                            <span class='more'id="more">
                            adolescents and their families to help them solve day to day challenges. Networking, communication and listening skills, assessment, analytical and evaluation skills as well as the ability to uphold client confidentiality are some of her strongest qualities.<br/><br/>
                            Her areas of expertise are:
                            <ul>
                                <li>counseling ( Psycho social support ),</li>
                                <li>Child protection issues</li>
                                <li>Individual and family therapy</li>
                                <li>Education and awareness of issues such as,</li>
                                <ul>
                                    <li>Cognitive stimulation </li>
                                    <li>Parenting skills</li>
                                    <li>Substance misuse</li>
                                    <li>Child Rights ( African Charter)</li>
                                    <li>Child Abuse</li>
                                    <li>Social media and cyber bullying</li>
                                    <li>Life coach/ motivational skills</li>
                                    <li>Anti bullying techniques </li>
                                    <li>Referral networks</li>
                                </ul>
                            </ul>
                            Her main goal is to come up with a fully functional individual who is  psychologically, cognitively , socially and emotionally developed.<br/><br/>
                            <h2 class="title">Qualifications:</h2><br/>
                            <ul>
                                <li>BA Honours Social Work with Great Zimbabwe University</li>
                                <li>Diploma in Systematic Family Therapy with Connect</li>
                                <li>Diploma in Education(Teaching)</li>
                            </ul>
                            
                            </span>
                        </p>
                        <button value="rosemary" class="myBtn btn btn-secondary" onClick={e => ReadMore(e.target.value)} id="myBtn">Read More</button>
                    </div>
                </div>

                <div class="col-md-4 col-sm-6">
                    <div class="our-team" data-city="ashley">
                        <div class="pic">
                            <img src={img3} alt='member pic'/>
                        </div>
                        <h3 class="title">Ashley Nyakonda</h3>
                        <span class="post">Intern Counselling Psychologist</span>
                        <ul class="social">
                            <li>
                                <a href="https://www.facebook.com/profile.php?id=100069952112245"><i class="fa fa-facebook"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/possibilitiescognitivecentre/"><i class="fa fa-instagram"></i></a>
                            </li>
                        </ul>

                        <p class="read">Ashley is a mental health advocate who is passionate about family therapy, adolescents and children with intellectual disabilities.<span class='dots' id="dots"> ........</span>
                            <span class='more'id="more">
                            She utilizes a variety of techniques, including Cognitive Behavioral Therapy, stress management, increasing self-worth, inner child work, assertiveness training, anxiety reduction and empowerment.<br/><br/>
                            <h2 class="title">Qualifications:</h2><br/>
                            <ul>
                                <li>Masters in Counselling Psychology with Great Zimbabwe University and a diploma in Systemic Family Therapy with Connect. </li>
                                <li>BSc Honours in Pyschology</li>
                                <li>Allied Health Practitioners Council of Zimbabwe (AHPCZ) number 068</li>
                            </ul>
                            </span>
                        </p>
                        <button value="ashley" class="myBtn btn btn-secondary" onClick={e => ReadMore(e.target.value)} id="myBtn">Read More</button>
                    </div>
                </div>
            </div>
            

        </div>
    </section>
  )
}

export default Team;

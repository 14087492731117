import React from 'react';

export const Hero = (props) => {
    return (
    <section class="bg-light w-100">
        <div class="container">
            <div class="row d-flex align-items-center py-5">
                <div class="col-lg-6 text-start">
                    <h1 class="h1 py-5 text-primary typo-space-line">{props.heading}</h1>
                    <p class="light-300">
                        {props.description}
                    </p>
                </div>
                <div class="col-lg-6">
                    <img src={props.src} alt={props.alt} />
                </div>
            </div>
        </div>
    </section>
    )
}
export default Hero;
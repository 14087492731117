import React from 'react';
import {Link} from 'react-router-dom';

const HeroItem = (props) => {
  return (
        <div className={props.active}>

            <div className="py-5 row d-flex align-items-center">
                <div className="banner-content col-lg-8 col-8 offset-2 m-lg-auto text-left py-5 pb-5">
                    <h1 id="banner" className="banner-heading h1 text-secondary display-3 mb-0 pb-3 mx-0 px-0 light-300">
                        {props.heading}
                    </h1>
                    <p id="banner" className="banner-body text-muted py-3">
                       {props.info}
                    </p>
                    <Link to="/contact-us" id="banner"className="banner-button btn rounded-pill btn-outline-primary btn-lg px-4" role="button">Contact Us</Link>
                </div>
            </div>

        </div>
  )
}

export default HeroItem

import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../Assets/img/pcsdc.png'


const Foot = () => {
    const navstyle={
        maxWidth:"200px"
    }
    return (
        <>
            <footer className="bg-secondary pt-4">
                <div className="container">
                    <div className="row py-4">

                        <div className="col-lg-3 col-12 align-left">
                            <Link to='/' className="navbar-brand">
                                <img style={navstyle} src={logo} alt="logo"/>
                            </Link>
                            <p className="text-light my-lg-4 my-2">
                               Possibilities  Cognitive skills Development Center provides excellent human management skills and human therapy solutions.
                            </p>
                            <ul className="list-inline footer-icons light-300">
                                <li className="list-inline-item m-0">
                                    <a className="text-light" rel="noreferrer" target="_blank" href="https://www.facebook.com/profile.php?id=100069952112245">
                                        <i className='bx bxl-facebook bx-md'></i>
                                    </a>
                                </li>
                                <li className="list-inline-item m-0">
                                    <a className="text-light" rel="noreferrer" target="_blank" href="https://www.instagram.com/possibilitiescognitivecentre/">
                                        <i className='bx bxl-instagram bx-md'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-4 my-sm-0 mt-4">
                            <h2 className="h4 pb-lg-3 text-light light-300">Our Company</h2>
                                <ul className="list-unstyled text-light light-300">
                                    <li className="pb-2">
                                        <i className='bx-fw bx bxs-chevron-right bx-xs'></i><Link to='/' className="text-decoration-none text-light">Home</Link>
                                    </li>
                                    <li className="pb-2">
                                        <i className='bx-fw bx bxs-chevron-right bx-xs'></i><Link to='/about' className="text-decoration-none text-light py-1">About Us</Link>
                                    </li>
                                    <li className="pb-2">
                                        <i className='bx-fw bx bxs-chevron-right bx-xs'></i><Link to='/our-work' className="text-decoration-none text-light py-1">Our Works</Link>
                                    </li>
                                    <li className="pb-2">
                                        <i className='bx-fw bx bxs-chevron-right bx-xs'></i><Link to='/contact-us' className="text-decoration-none text-light py-1">Contact</Link>
                                    </li>
                                </ul>
                        </div>

                        <div className="col-lg-3 col-md-4 my-sm-0 mt-4">
                            <h2 className="h4 pb-lg-3 text-light light-300">Our Works</h2>
                            <ul className="list-unstyled text-light light-300">
                                <li className="pb-2">
                                    <i className='bx-fw bx bxs-chevron-right bx-xs'></i><Link className="text-decoration-none text-light py-1" to='/our-work'>Services and Programs</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-3 col-md-4 my-sm-0 mt-4">
                            <h2 className="h4 pb-lg-3 text-light light-300">For Client</h2>
                            <ul className="list-unstyled text-light light-300">
                                <li className="pb-2">
                                    <i className='bx-fw bx bx-phone bx-xs'></i><a className="text-decoration-none text-light py-1" href="tel:010-020-0340">+263 772974015 / +263 772306110</a>
                                </li>
                                <li className="pb-2">
                                    <i className='bx-fw bx bx-mail-send bx-xs'></i><a className="text-decoration-none text-light py-1" href="mailto:info@possibilitiescognitiveskills.com">info@possibilitiescognitiveskills.com</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div className="w-100 bg-primary py-3">
                    <div className="container">
                        <div className="row pt-2">
                            <div className="col-lg-6 col-sm-12">
                                <p className="text-lg-start text-center text-light light-300">
                                    © Copyright 2021 Possibilities Cognitive Skills Development Center. All Rights Reserved.
                                </p>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <p className="text-lg-end text-center text-light light-300">
                                    Designed by <a rel="noreferrer" className="text-decoration-none text-light" href="https://melgotech.co.zw" target="_blank"><strong>Melgotech Incorporated.</strong></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    )
}

export default Foot

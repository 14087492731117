import React from 'react';
// import of components needed for the page
import Hero from '../Components/Hero';
import img from '../Assets/img/notfound.svg';

const NotFound = () => {
  return (
    <>
      <Hero 
          heading="Page Not Found"
          description="Sorry, the Page that you are trying to access is not available on this wesbsite."
          alt="back image"
          src={img}
      />
    </>
  )
}

export default NotFound;

import React from 'react'

const Contactitem = (props) => {
  return (
                <div className="contact row mb-4">
                    <div className="contact-icon col-lg-3 col-3">
                        <div className="py-3 mb-2 text-center border rounded text-secondary">
                            <i className={props.icon}></i>
                        </div>
                    </div>
                    <ul className="contact-info list-unstyled col-lg-9 col-9  light-300">
                        <li className="h5 mb-0">{props.title}</li>
                        <li className="text-muted">{props.info}</li>
                    </ul>
                </div>
  )
}

export default Contactitem

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../Assets/img/pcsdc.png'

const Navbar = () => {
    const navstyle={
        maxWidth:"200px"
    }
  return (
    // opening header info
    <nav id="main_nav" className="navbar navbar-expand-lg navbar-light bg-white shadow">
        <div className="container d-flex justify-content-between align-items-center">
            <Link to='/' className="navbar-brand h1">
                <img style={navstyle} src={logo} alt="logo"/>
            </Link>
            <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-toggler-success" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="align-self-center collapse navbar-collapse flex-fill  d-lg-flex justify-content-lg-between" id="navbar-toggler-success">
                <div className="flex-fill mx-xl-5 mb-2">
                    <ul className="nav navbar-nav d-flex justify-content-between mx-xl-5 text-center text-dark">
                        <li className="nav-item">
                            <Link to='/' className="nav-link btn-outline-primary rounded-pill px-3"><i className='bx bx-home bx-sm bx-tada-hover'></i><span className="size"> Home</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/about' className="nav-link btn-outline-primary rounded-pill px-3"><i className='bx bx-question-mark bx-sm bx-tada-hover'></i><span className="size"> About</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/our-work' className="nav-link btn-outline-primary rounded-pill px-3"><i className='bx bx-book bx-sm bx-tada-hover'></i><span className="size"> Our work</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link to='/contact-us' className="nav-link btn-outline-primary rounded-pill px-3"><i className='bx bx-envelope bx-sm bx-tada-hover'></i><span className="size"> Contact Us</span></Link>
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    </nav>
    //closing header info

  )
}

export default Navbar;

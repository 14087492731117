import React from 'react';
// import of components needed for the page
import Hero from '../Components/Hero';
import Team from '../Components/Team';
import img from '../Assets/img/banner-img-02.svg';
import aboutimg from '../Assets/img/about.jpg';
import Mission from '../Components/Mission';
import AboutSection from '../Components/AboutSection';

const About = () => {
  return (
    <>
      <Hero 
          heading="About Us"
          description="Scroll down and get to know us better in detail with all the the company info."
          alt="back image"
          src={img}
      />
      <AboutSection 
        img={aboutimg}
        heading="About Us"
        info={<fragment>We believe that cognitive skills training is about exercising the brain to think faster and learn easily. 
                        Different children have different needs that is why our programs target the areas that need strengthening whether
                        it be memory, auditory processing, visual processing, attention, working memory, processing speed or logic and 
                        reasoning.<br/><br/>
                        <ul>
                        <li>Take the first step today and register for an assessment</li>
                        <li>Second step is going through our programs</li>
                        <li>Third step is enjoy your new found abilities and continue on your path of exploring more possibilities</li>
                        </ul>
                        Our training is Diagnostic, individual and developmental.  Our trainers are qualified and experienced to deliver timely, systematic and effective interventions.
                        All our programs are research based and international.</fragment>}
      />
      <Team />
      <Mission color="banner-bg bg-white py-5"/>
    </>
  )
}

export default About;

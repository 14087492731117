import React from 'react';
import ServiceItem from './ServiceItem';
import img1 from '../Assets/img/brain.jpg'
import img2 from '../Assets/img/counselling.jpg';
import img3 from '../Assets/img/mental.jpg'
import img4 from '../Assets/img/speech.jpg'
import img5 from '../Assets/img/day.jpg'
import img6 from '../Assets/img/nutrition.jpg'
import img7 from '../Assets/img/psych.jpg'
import img8 from '../Assets/img/education.jpg'
import img9 from '../Assets/img/rp.jpg'


const Services = () => {
    const pillstyle={
        border: "none",
        background: "transparent",
    }
  return (
    <>
        <section className="service-wrapper bg-light py-3">
            <div className="container-fluid pb-3">
                <div className="row">
                    <h2 className="h2 text-center col-12 py-5 semi-bold-600">Services</h2>
                    <div className="service-header col-2 col-lg-3 text-end light-300">
                        <i className='bx bx-gift h3 mt-1'></i>
                    </div>
                    <div className="service-heading col-10 col-lg-9 text-start float-end light-300">
                        <h2 className="h3 pb-4 typo-space-line">Create a Stress Free Environment</h2>
                    </div>
                </div>
                <p className="service-footer col-10 offset-2 col-lg-9 offset-lg-3 text-start pb-3 text-muted px-2">
                    Let us help you manage the different aspects of the cognitive mindset through a range of services that Possibilities Cognitive Skills Development Center offers. Click on the filters below to see the various services.
                </p>
            </div>

            <div className="service-tag py-5 bg-secondary">
                <div className="col-md-12">
                    <ul className="nav d-flex justify-content-center">
                        <li className="nav-item mx-lg-4">
                            <button style={pillstyle} className="filter-btn nav-link btn-outline-primary active shadow rounded-pill text-light px-4 light-300" data-filter=".project">All</button>
                        </li>
                        <li cspanassName="nav-item mx-lg-4">
                            <button style={pillstyle} className="filter-btn nav-link btn-outline-primary rounded-pill text-light px-4 light-300"  data-filter=".children">Children & Adolecense</button>
                        </li>
                        <li className="filter-btn nav-item mx-lg-4">
                            <button style={pillstyle} className="filter-btn nav-link btn-outline-primary rounded-pill text-light px-4 light-300"  data-filter=".adult">Adults</button>
                        </li>
                        <li cspanassName="nav-item mx-lg-4">
                            <button style={pillstyle} className="filter-btn nav-link btn-outline-primary rounded-pill text-light px-4 light-300"  data-filter=".daytherapy">Day Therapy</button>
                        </li>
                    </ul>
                </div>
            </div>

        </section>

        <section className="container overflow-hidden py-5">
            <div className="row gx-5 gx-sm-3 gx-lg-5 gy-lg-5 gy-3 pb-3 projects">

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project children"
                    src={img1}
                    head="Brainobrain Program"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project children adult"
                    src={img2}
                    head="Counselling"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project adult children"
                    src={img3}
                    head="Mental Health Training"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project children"
                    src={img4}
                    head="Speech and Language"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project daytherapy"
                    src={img5}
                    head="Day Therapy"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project adult"
                    src={img6}
                    head="Nutrition and Diet"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project adult children"
                    src={img7}
                    head="Psychological Services"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project adult"
                    src={img8}
                    head="School Based Support"
                    info=""
                />

                <ServiceItem
                    classinfo="col-xl-3 col-md-4 col-sm-6 project children"
                    src={img9}
                    head="Reading Program"
                    info=""
                />

            </div>
        </section>
        

    </>
  )
}

export default Services

import React from 'react';
import {Link} from 'react-router-dom'

const NiceHero = (props) => {
    const styles={
        color: "white"
    }
    return (
        <>
            <div id="work_banner" class="banner-wrapper bg-light w-100 py-5">
                <div class="banner-vertical-center-work container text-light d-flex justify-content-center align-items-center py-5 p-0">
                    <div class="banner-content col-lg-8 col-12 m-lg-auto text-center">
                        <h1 class="banner-heading h2 display-3 pb-5 semi-bold-600 typo-space-line-center">{props.title}</h1>
                        <h3 class="h4 pb-2 regular-400">{props.subtitle}</h3>
                        <p class="banner-body pb-2 light-300">
                           {props.info}
                        </p>
                        <Link style={styles} to="/contact-us" className="banner-button btn rounded-pill btn-outline-primary btn-lg px-4" role="button">Contact Us</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NiceHero

import React from 'react';
import qoute from '../Assets/img/qoute.png';

const TestItem = (props) => {
  return (
    <>
                <div class="col-lg-4 col-md-6 col-sm-12">
                    <div class="team-item">
                        <div class="team-content">
                            <i><img src={qoute} alt=""/></i>
                            <p>"{props.quote}"</p>
                            <div class="user-image">
                                <i class="fa fa-quote-right"></i>
                            </div>
                            <div class="team-info">
                                <h3 class="user-name">{props.name}</h3>
                            </div>
                        </div>
                    </div>
                </div>
    </>
  )
}

export default TestItem;

import React, { useState } from 'react';
import ContactItem from './Contactitem'

const FormSection = () => {

    const [inputs, setInputs] = useState({});
    const [textarea, setTextarea] = useState(
        ""
    );

    const handleTextArea = (event) => {
        setTextarea(event.target.value)
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}))
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        alert(inputs);
    }
  return (
    <section className="container py-5">

        <h1 className="col-12 col-xl-8 h2 text-left text-primary pt-3">GET IN TOUCH WITH US!</h1>
        
        <p className="col-12 col-xl-8 text-left text-muted pb-5 light-300">
            Write us a message on this platform and we will reply within 12 working hours, for any booking and services that might be required.
        </p>

        <div className="row pb-4">

            <div class="col-lg-4">
                <div id="map">
                  <iframe title="PCSDC MAP" scrolling="yes" src="https://maps.google.com/maps?q=Milton%20Park,%20Harare,%20Zimbabwe&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="636px" frameborder="0"allowfullscreen></iframe>
                </div>
            </div>
            

            
            <div className="col-lg-8 ">
                <form className="contact-form row" method="post" action="#">

                    <div className="col-lg-6 mb-4">
                        <div className="form-floating">
                            <input type="text" className="form-control form-control-lg light-300" id="floatingname" name="inputname" value={inputs.username || ""} 
        onChange={handleChange}placeholder="Name" />
                            <label for="floatingname light-300">Name</label>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <div className="form-floating">
                            <input type="text" className="form-control form-control-lg light-300" id="floatingemail" name="inputemail" placeholder="Email" />
                            <label for="floatingemail light-300">Email</label>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <div className="form-floating">
                            <input type="text" className="form-control form-control-lg light-300" id="floatingphone" name="inputphone" placeholder="Phone" />
                            <label for="floatingphone light-300">Phone</label>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-4">
                        <div className="form-floating">
                            <input type="text" className="form-control form-control-lg light-300" id="floatingcompany" name="inputcompany" placeholder="Company Name" />
                            <label for="floatingcompany light-300">Company Name</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-floating mb-4">
                            <input type="text" className="form-control form-control-lg light-300" id="floatingsubject" name="inputsubject" placeholder="Subject" />
                            <label for="floatingsubject light-300">Subject</label>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="form-floating mb-3">
                            <textarea onChange={handleTextArea} className="form-control light-300" rows="8" value={textarea}placeholder="Message" id="floatingtextarea"></textarea>
                            <label for="floatingtextarea light-300">Message</label>
                        </div>
                    </div>

                    <div className="col-md-12 col-12 m-auto text-end">
                        <button onClick={handleSubmit} type="submit" className="btn btn-secondary rounded-pill px-md-5 px-4 py-2 radius-0 text-light light-300">Send Message<i class="bx-fw bx bx-mail-send"></i></button>
                    </div>

                </form>

                
                    
                        <ContactItem
                            icon="bx bx-map display-6"
                            title="Address"
                            info="18217 St.Milton Park, Harare"
                        />
                    
                        <ContactItem
                            icon="bx bx-envelope display-6"
                            title="Email"
                            info="info@possibiltiescognitiveskills.com"
                        />

                        <ContactItem
                            icon="bx bx-phone display-6"
                            title="Phone Numbers"
                            info="+263 772974015 / +263 772306110"
                        />
                    

            </div>
           
             

        </div>
    </section>
  )
}

export default FormSection

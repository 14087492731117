import React from 'react';


const AboutSection = (props) => {
  return (
    <>
      <section class="bg-white py-5">
          <div class="container my-4">

              <h2 class="h2 py-5 typo-space-line">{props.heading}</h2>

              <div class="creative-content row py-3">
                  <div class="col-md-5">
                      <img src={props.img} alt="about" class="about-img" />
                  </div>
                  <div class="creative-progress col-md-7">
                        <h1 class="pricing-header h2 semi-bold-600 h2">Information</h1>
                        <p class="text-muted">
                            {props.info}
                        </p>
                  </div>
              </div>
          </div>
      </section>
    </>
  )
}

export default AboutSection

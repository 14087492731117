import React from 'react'

const MissionItem = (props) => {
  return (
    <>
                    <div className="objective col-lg-4">
                        <div className="objective-icon card m-auto py-4 mb-2 mb-sm-4 bg-secondary shadow-lg">
                            <i className={props.icon}></i>
                        </div>
                        <h2 className="objective-heading h3 mb-2 mb-sm-4 light-300">{props.title}</h2>
                        <p className="light-300">
                            {props.desc}
                        </p>
                    </div>
    </>
  )
}

export default MissionItem

import React from 'react';
import NiceHero from '../Components/NiceHero';
import Works from '../Components/Works';

const OurWork = () => {
    return (
        <>
            <NiceHero 
                    title="Our Work"
                    subtitle="Take a quick Glance at our work."
                    info="Scrool down and see some of the works done at Possibility Cognitive Skills Development Center, which offers a vast range of services"
            />
            <Works />
        </>
    )
}

export default OurWork;

import React from 'react';
import '../Assets/testimonials.css';
import TestItem from './TestItem';

const Testimonials = () => {
  const styles ={
        display:"block"
    }
  return (
    <>
    <section style={styles} class="section" id="testimonials">
        <div class="container">
            
            <div class="row">
                <div class="col-lg-12">
                    <div class="center-heading">
                        <h2 class="section-title text-center"> Some bonus Quotes</h2>
                    </div>
                </div>
                <div class="offset-lg-3 col-lg-6">
                    <div class="text-center">
                        <p>Here are a few quotes to get you motivated for the day.</p>
                    </div>
                </div>
            </div>
            
            <div class="row">
               <TestItem 
                  name="Ayubakhan U."
                  quote="Explore the possibilities; Transformation happens anywhere "
               />

               <TestItem 
                  name="Mildred D. Taylor"
                  quote="So many things are possible as long as you know they are impossible. "
               />

                <TestItem 
                  name="Nicholas Sparks"
                  quote="It's the possibility that keeps me going, not the guarantee. "
               />

               <TestItem 
                  name="Ogwo David Emenike"
                  quote="There is a thin line between the impossible and the possible that is determination."
               />

               <TestItem 
                  name="Pablo"
                  quote="My life is full of unlimited possibilities"
               />

               <TestItem 
                  name="Ronald Reagan"
                  quote="Every new day begins with possibilities."
               />
            </div>
        </div>
    </section>
    </>
  )
}

export default Testimonials
